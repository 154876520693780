import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Pocket1col from "src/components/pocket1col"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import CtadisclosureBing from "src/components/ctadisclosureBing"
import Ctadisclosurechff from "src/components/ctadisclosurechff"

const pocketData = import("./data/data.json");

export default function spabm2() {

  let disclosure = null;
  if(isChrome()){
    disclosure = <Ctadisclosurechff  data={pocketData}></Ctadisclosurechff>;
  }
  else{
    disclosure = <CtadisclosureBing data={pocketData}></CtadisclosureBing>;
  }

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`

        .footerlinkssingle-module--br {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        #footerlinkssingle-module--footerlinkssingle .footerlinkssingle-module--br {
          margin: 50px auto 0;
        }

        #ctadisclosurebing-module--ctadisclaimerbing, #ctadisclosurebing-module--ctadisclaimerbing a {
          color: #666;
        }
    `}
    </style>

    <title>Save any Webpage Easily - EasySave.app</title></Helmet>
    <section>
      <Pocket1col data={pocketData}>
      {disclosure}
    </Pocket1col>
      </section>
    </HomepageLayout>
  )
}
